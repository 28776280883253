import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '../views/404.vue'
// import Demo from '../views/Demo.vue'
import Demo from '../views/all-home-version/MultiScrollPortfolio.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: Demo,
        meta: {
            title: 'Olrro || A Plumilla'
        }
    },
    {
        path: '/main-demo',
        name: 'MainDemo',
        meta: {
            title: 'Olrro || Main Demo Portfolio'
        },
        component: () =>
            import ('../views/all-home-version/MainDemo.vue')
    },
    {
        path: '/freelancer',
        name: 'Freelancer',
        meta: {
            title: 'Olrro || Freelancer Portfolio'
        },
        component: () =>
            import ('../views/all-home-version/Freelancer.vue')
    },
    {
        path: '/agency',
        name: 'Agency',
        meta: {
            title: 'Olrro || Agency Portfolio'
        },
        component: () =>
            import ('../views/all-home-version/Agency.vue')
    },
    {
        path: '/minimal-agency',
        name: 'MinimalAgency',
        meta: {
            title: 'Olrro || Minimal Agency Portfolio'
        },
        component: () =>
            import ('../views/all-home-version/MinimalAgency.vue')
    },
    {
        path: '/creative-portfolio',
        name: 'CreativePortfolio',
        meta: {
            title: 'Olrro || Creative Portfolio'
        },
        component: () =>
            import ('../views/all-home-version/CreativePortfolio.vue')
    },
    {
        path: '/designer-portfolio',
        name: 'DesignerPortfolio',
        meta: {
            title: 'Olrro || Designer Portfolio'
        },

        component: () =>
            import ('../views/all-home-version/DesignerPortfolio.vue')
    },
    {
        path: '/vertical-portfolio',
        name: 'VerticalPortfolio',
        meta: {
            title: 'Olrro || Vertical Portfolio'
        },
        component: () =>
            import ('../views/all-home-version/VerticalPortfolio.vue')
    },
    {
        path: '/multiscroll-portfolio',
        name: 'MultiScrollPortfolio',
        meta: {
            title: 'Olrro || Multiscroll Portfolio'
        },
        component: () =>
            import ('../views/all-home-version/MultiScrollPortfolio.vue')
    },
    {
        path: '/parallax-home',
        name: 'ParallaxHome',
        meta: {
            title: 'Olrro || Parallax Portfolio'
        },
        component: () =>
            import ('../views/all-home-version/ParallaxHome.vue')
    },
    {
        path: '/portfolio-details',
        name: 'PortfolioDetails',
        meta: {
            title: 'Olrro || Portfolio Details'
        },
        component: () =>
            import ('../views/PortfolioDetails.vue')
    },
    {
        path: '/mano-alzada',
        name: 'ManoAlzada',
        meta: {
            title: 'Olrro || Mano Alzada'
        },
        component: () =>
            import ('../views/ServiceDetails.vue')
    },
    {
        path: '/bodas-eventos',
        name: 'BodasEventos',
        meta: {
            title: 'Olrro || Bodas y Eventos'
        },
        component: () =>
            import ('../views/ServiceDetails2.vue')
    },
    {
        path: '/Decoracion',
        name: 'Decoracion',
        meta: {
            title: 'Olrro || Decoracion'
        },
        component: () =>
            import ('../views/ServiceDetails3.vue')
    },
    {
        path: '/blog-details',
        name: 'BlogDetails',
        meta: {
            title: 'Olrro || Blog Detalles'
        },
        component: () =>
            import ('../views/BlogDetails.vue')
    },
    {
        path: '/contact',
        name: 'Contact',
        meta: {
            title: 'Olrro || Contacta'
        },
        component: () =>
            import ('../views/Contact.vue')
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        meta: {
            title: 'Olrro || Sobre Mi'
        },
        component: () =>
            import ('../views/AboutUs.vue')
    },
    {
        path: '/awards',
        name: 'Awards',
        meta: {
            title: 'Olrro || Awards'
        },
        component: () =>
            import ('../views/Awards.vue')
    },
    {
        path: '/blog',
        name: 'Blog',
        meta: {
            title: 'Olrro || Blog'
        },
        component: () =>
            import ('../views/Blog.vue')
    },
    {
        path: '/portfolio',
        name: 'Portfolio',
        meta: {
            title: 'Olrro || Portfolio'
        },
        component: () =>
            import ('../views/Portfolio.vue')
    },
    {
        path: '/service',
        name: 'Service',
        meta: {
            title: 'Olrro || Servicios'
        },
        component: () =>
            import ('../views/Service.vue')
    },
    {
        path: '*',
        component: Missing,
        meta: {
            title: 'Olrro || 404'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


export default router