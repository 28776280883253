<template>
  <div class="ms-container-wrapper">
    <div class="ms-left">
      <div
        class="ms-section bg_image "
        :style="{ backgroundImage: 'url(' + src + ')' }"
      ></div>
    </div>
    <div class="ms-right">
      <div class="ms-section ms-table">
        <div class="slipt-content-inner ms-tableCell rn-plr rn-ptb-100">
          <div class="story-content">
            <div class="text-left content">
              <h2 class="section-title">Mis trabajos</h2>
              <p>
                En mi portafolio podrás encontrar ejemplos de algunos de mis trabajos anteriores. Los servicios que ofrezco incluyen dibujos a mano alzada en color y blanco y negro.
              </p>
              <p>
                Estos dibujos son ideales para personalizar y hacer único cualquier tipo de evento, ya sea una boda, un cumpleaños, un aniversario, o cualquier otra ocasión que merezca un regalo especial. 
              </p>
              <div class="story-btn">
                <router-link to="/designer-portfolio"
                  >MIRA MI PORTFOLIO</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        src: require("../../assets/img/portfolio/my-skill.jpg"),
      };
    },
  };
</script>

<style lang="scss" scoped></style>
