<template>
  <div class="ms-container-wrapper">
    <div class="ms-left">
      <div
        class="ms-section bg_image "
        :style="{ backgroundImage: 'url(' + src + ')' }"
      ></div>
    </div>
    <div class="ms-right">
      <div class="ms-section ms-table">
        <div
          class="slipt-content-inner ms-tableCell story-content rn-plr rn-ptb-100"
        >
          <div class="content">
            <h2 class="section-title">Servicios</h2>
            <div class="service-wrapper">
              <!-- Start Single Service -->
              <div
                class="service"
                v-for="(service, i) in serviceContent"
                :key="i"
              >
                <h4>
                  <router-link :to="service.targetRoute">{{
                    service.title
                  }}</router-link>
                </h4>
                <p>
                  {{ service.desc }}
                </p>
                <h4 v-if="service.customService">
                  {{ service.customService }}
                </h4>
                <p v-if="service.lastItem">
                  
                  <router-link :to="service.contact"></router-link>
                </p>
              </div>
              <!-- End Single Service -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        src: require("../../assets/img/service/services-1.jpg"),
        serviceContent: [
          {
            targetRoute: "/empezó-esto",
            title: "Cómo empezó esto",
            desc: `Desde muy pequeño he disfrutado con el dibujo y la pintura como afición y como dibujante autodidacta. Me gusta captar paisajes urbanos o rurales en mis libretas de dibujo y no suelo salir a pasear sin ellas. Creo que te permite apreciar y valorar los detalles de lo que estás viendo, más allá de realizar una simple foto. Durante años he dibujado y pintado para amigos y familiares, recreando paisajes o edificios que para ellos pudieran tener una particular connotación o simplemente como series decorativas para sus casas de alguna temática particular, aves, fauna, caza, vela o barcos entre otras.  Mi origen Mediterráneo me ha acercado mucho a las temáticas marinas.`,
          },
          {
            targetRoute: "/bodas-eventos",
            title: "Dibujos para bodas y eventos",
            desc: `También he realizado como pintor amateur algún encargo para un regalo original o para un evento especial como puede ser una boda, realizando las ilustraciones de las participaciones del enlace, plano de la ubicación del lugar de la ceremonia o distribución de las mesas de invitados.`,
          },
          {
            targetRoute: "/amigos-web",
            title: "Hazte una web, me dijeron",
            desc: `Animado por estos “clientes amigos” que he tenido el placer de poder atender hasta ahora, he construido esta web para ofrecer mis servicios para dibujos por encargo a cualquiera que pueda estar interesado. Como le pedía el Principito al piloto perdido en el desierto “dibújame un cordero”, y yo, estoy dispuesto a pintártelo.`,
          },
          {
            targetRoute: "/service-details",
            customService: "",
            contact: "/contact",
            lastItem: true,
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped></style>
